import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import EditorU from '../../components/popup/EditorUeditor';
export default {
  name: "CommonDrawer",
  props: ['drawerData', 'drawerInit', 'resetData', 'title'],
  data: function data() {
    return {
      drawer: false,
      isEditorShow: false,
      ueidtorName: 'ueditor',
      val: '',
      modeType: 'descript',
      nowArticleData: '',
      newDes: ''
    };
  },
  components: {
    EditorU: EditorU
  },
  created: function created() {},
  mounted: function mounted() {
    // console.log("弹窗数据",this.drawerData)
  },
  methods: {
    showEditor: function showEditor(model) {
      console.log(model);
      this.nowArticleData = {
        'content': model
      };
      this.isEditorShow = true;
    },
    cancelDrawer: function cancelDrawer() {
      // this.$emit("resetData");
      // this.$parent.resetDataFn();
      this.$refs.form.resetFields();
      /*    this.$refs.form.validate((valid) => {
            //表单恢复初始化
            this.$refs.form.resetFields();
          })*/

      this.drawer = false;
    },
    //确定
    confirmClick: function confirmClick(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        _this.$emit('confirm', valid, _this.$refs[formName], _this.drawerData);
      });
    },
    //
    selectChangeParent: function selectChangeParent(id, k) {
      // alert(JSON.stringify(this.drawerInit.list[k].drop.model))
      var idDes = this.drawerInit.list[k].drop.des.id;
      var nameDes = this.drawerInit.list[k].drop.des.name;
      var valueDes = this.drawerInit.list[k].drop.des.value;
      var modelId = this.drawerInit.list[k].drop.model.id;
      var modelValue = this.drawerInit.list[k].drop.model.value;
      // this.drawerData[modelId]=this.drawerInit.list[k].drop.list[index][idDes];
      // this.drawerData[nameDes]=this.drawerInit[index][nameDes];
      if (valueDes) {
        var index = this.searchId(id, this.drawerInit.list[k].drop.list, idDes);
        this.drawerData[modelValue] = this.drawerInit.list[k].drop.list[index][valueDes];
      }
    },
    //查找选中的id
    searchId: function searchId(id, list, idStr) {
      for (var i = 0; i < list.length; i++) {
        if (list[i][idStr] == id) {
          return i;
        }
      }
      return -1;
    }
  }
};