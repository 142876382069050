import _objectSpread from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var _this3 = this;
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { Edit } from '@element-plus/icons-vue';
import SearchSelect from '../../components/common/Search';
import { courseSearchList } from '../../assets/js/data';
import CommonTable from '../../components/common/CommonTable';
import { coursestructureOperation, sendThis } from '../../data/course/courseData';
import { courseStructure } from '../../data/courseTable';
import { courseStructureDrawer } from '../../data/courseDrawer';
import CommonDrawer from '../../components/common/CommonDrawer';
export default {
  name: "CourseStructure",
  data: function data() {
    return {
      navData: [{
        //面包屑
        meta: {
          title: "课程"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程中心"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程管理"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "结构管理"
        },
        path: "/course/structure"
      }],
      isEdit: false,
      //默认是添加
      drawerKey: 0,
      //抽屉key
      courseStructureDrawer: courseStructureDrawer,
      coursestructureOperation: coursestructureOperation,
      courseStructure: courseStructure,
      structureDrop: [],
      //下拉结构
      structurelevelDrop: [],
      //标准下拉结构
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      //获取用户数据
      tableKey: 1,
      //避免数据更新表格视图不更新
      tableData: [],
      total: 0,
      //页数总数
      currentPage: parseInt(this.$route.query.currentPage ? this.$route.query.currentPage : 1),
      // 当前页数
      pageSize: parseInt(this.$route.query.pageSize ? this.$route.query.pageSize : 10),
      //每页个数
      courseSearchList: courseSearchList,
      courseData: JSON.parse(localStorage.getItem("course")),
      state: true,
      //课程状态
      Edit: Edit,
      //图标
      imageUrl: "",
      //课程图标
      resetData: null,
      //结构数据的初始值 永远不变，用来还原用的
      localCourseData: JSON.parse(localStorage.getItem("course")),
      //当前课程额数据
      structureData: {
        //添加结构数据
        userId: this.Ls.getParseToken('nof_userInfo').userId,
        roleId: this.Ls.getParseToken('nof_userInfo').roleId,
        name: "",
        //阶段
        courseId: JSON.parse(localStorage.getItem("course")).id,
        //课程ID
        courseName: JSON.parse(localStorage.getItem("course")).name,
        //课程ID
        stdlevelId: "",
        //标准课程结构层级ID
        stdlevelValue: "",
        //value
        pid: "",
        //父级ID
        haveResource: 0,
        //本层结构是否带课程资源：0无 1有
        description: "",
        //描述
        rank: 1,
        //排列顺序
        state: 1 //状态：1正常 -1禁用
      } //添加结构、修改结构的数据
    };
  },

  watch: {
    '$route': 'initData'
  },
  components: {
    SearchSelect: SearchSelect,
    CommonTable: CommonTable,
    CommonDrawer: CommonDrawer
  },
  mounted: function mounted() {
    //发送当前实例
    sendThis(this);
    //设置面包屑
    this.$emit('navigation', this.navData);
  },
  created: function created() {
    //拿到初始值
    this.resetData = _objectSpread({}, this.structureData);

    // this.initDataJs();
    this.initData();
  },
  methods: {
    resetDataFn: function resetDataFn() {
      //弹框的数据初始化
      this.structureData = _objectSpread({}, this.resetData);
    },
    //确认弹框的内容
    dialogContent: function dialogContent(json, row) {
      json.content = "确定删除" + row.name + "吗？";
      return json;
    },
    initDataJs: function initDataJs() {
      // this.coursestructureOperation[1].clickFn();
    },
    //删除结构
    deleteFn: function deleteFn(row) {
      var _this = this;
      this.service.axiosUtils({
        requestName: 'deleteStructureDefinition',
        data: {
          id: row.id
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this.centerDialogVisible = false;
            _this.$toast.success(res.message);
            _this.initData();
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    //编辑结构
    edit: function edit(row) {
      var _this2 = this;
      this.resetDataFn();
      this.isEdit = true;
      this.showAddCourseDrawer('edit');
      /*      this.isEdit=true;
            this.showAddCourseDrawer();
            this.resetDataFn();*/
      setTimeout(function () {
        _this2.structureData = _objectSpread({}, row);
        _this2.structureData.courseName = _this2.courseData.name;
        _this2.structureData.pid = row.parent ? row.parent.id : 0;
        _this2.structureData.roleId = _this2.Ls.getParseToken('nof_userInfo').roleId;
      }, 0);
    },
    //显示添加课程结构定义抽屉弹框
    showAddCourseDrawer: function showAddCourseDrawer(type) {
      if (type == "add") {
        this.isEdit = false;
      } else if (type == "edit") {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
      //显示抽屉弹框
      this.$refs.commonDrawer.drawer = true;
    },
    handleAvatarSuccess: function handleAvatarSuccess(response, uploadFile) {
      _this3.imageUrl = URL.createObjectURL(uploadFile.raw);
    },
    //获取课程下拉（隶属上级）
    getStructuredefinitionDrop: function getStructuredefinitionDrop() {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: 'getStructuredefinitionDrop',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          courseId: this.courseData.id
        },
        sucFn: function sucFn(res) {
          _this4.structureDrop = res.data;
          _this4.courseStructureDrawer.list[3].drop.list = _this4.structureDrop;
          _this4.courseStructureDrawer.list[3].drop.list.push({
            id: 0,
            name: "无",
            value: 0
          });
          // this.drawerKey++;
        }
      });
    },
    //获取标准课程结构下拉
    getStandardlevelDrop: function getStandardlevelDrop() {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: 'getStandardlevelDrop',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId
        },
        sucFn: function sucFn(res) {
          _this5.structurelevelDrop = res.data;
          _this5.courseStructureDrawer.list[1].drop.list = _this5.structurelevelDrop;
          // this.drawerKey++;
        }
      });
    },
    //过滤表格的是否有资源
    formatter: function formatter(row, column) {
      var isHava = "否";
      if (row.haveResource == 0) {
        isHava = "否";
      } else {
        isHava = "是";
      }
      return isHava;
    },
    nextPage: function nextPage(t) {
      switch (t) {
        case 1:
          this.$router.push({
            path: "/course/edit/structure"
          });
          break;
      }
    },
    //修改状态
    changeState: function changeState(value) {
      var _this6 = this;
      this.courseData.roleId = this.userInfo.roleId;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        id: value.id
      };
      if (value.state == 1) {
        postData.state = -1;
      } else {
        postData.state = 1;
      }
      this.service.axiosUtils({
        requestName: 'structuredefinitionChangeState',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this6.$toast.success(res.message);
          } else {
            _this6.$toast.error(res.message);
          }
          _this6.initData();
        }
      });
    },
    //初始化获取数据
    initData: function initData() {
      var _this7 = this;
      this.service.axiosUtils({
        requestName: 'getAllStructuredefinition',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          courseId: this.courseData.id,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10,
          pageIndex: this.$route.query.currentPage ? this.$route.query.currentPage : 1
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this7.tableData = res.data.data;
            _this7.total = res.data.total;
            _this7.tableKey++;
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });

      //获取课程结构（隶属上级）
      this.getStructuredefinitionDrop();
      if (this.structurelevelDrop.length == 0) {
        this.getStandardlevelDrop();
      }
    },
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      // 获取最大页数
      var pageMax = Math.ceil(this.total / this.pageSize);
      // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
      if (this.currentPage > pageMax) {
        this.currentPage = pageMax;
      }
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: page
        }
      });
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10
        }
      });
    },
    //添加课程结构定义确定
    confirmClick: function confirmClick(valid, formRef, formData) {
      var _this8 = this;
      if (valid) {
        var requestName = "";
        if (this.isEdit) {
          requestName = "editStructureDefinition";
        } else {
          //添加
          requestName = "addStructureDefinition";
        }
        // console.log(this.structureData);
        this.service.axiosUtils({
          requestName: requestName,
          data: this.structureData,
          sucFn: function sucFn(res) {
            if (res.status == 1) {
              _this8.drawer = false;
              _this8.$toast.success(res.message);
              _this8.$refs.commonDrawer.drawer = false;
              formRef.resetFields();
              _this8.initData();
            } else {
              _this8.$toast.error(res.message);
            }
          }
        });
      }
    }
  }
};