import router from '../../router'


// 用来存放调用此js的vue组件实例（this）
let vm = null

export var sendThis = ( _this )=> {
  vm = _this
}

//帮助中心（操作按钮）
export var helpOperation={
  width:200,
  type: 3, //操作
  label:"操作",
  buttonList:[{
    buttonType:"primary",
    label:"编辑",
    callBackFn:"edit",
    clickFn(row){
      vm.editHelp(row);
    }
  },{
    buttonType:"primary",
    label:"禁用",
    labelData:{
      labelType:1,//1禁用启用模式
    },
    clickFn(row){
      vm.changeState(row);
    }
  },{
    buttonType:"danger",
    label:"删除",
    dialog: {
      type:1,
      data:{
        title:"删除课程结构定义",
        content:"确定删除课程结构定义吗？"
      }
    },//弹框样式
    clickFn(row){
      vm.deleteFn(row);
    }
  }
  ]
}
//帮助中心（状态）
export var helpState={
  width:90,
  type: 2, //状态  正常或者禁用按钮
  property:"state",
  label:"状态",
  clickFn(row){
    //是点击的方法
    vm.changeState(row);
  }
}
//帮助中心（是否置顶）
export var helpTop={
  width:90,
  type: 2, //状态  正常或者禁用按钮
  property:"isTop",
  label:"是否置顶",
  sucText:"是",
  danger:"否",
  disabled:false,
  clickFn(row){
    //是点击的方法
    vm.changeTop(row);
  }
}
