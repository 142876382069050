import router from '../../router'
import { ref} from 'vue'


// 用来存放调用此js的vue组件实例（this）
let vm = null

export var sendThis = ( _this )=> {
  vm = _this
}
export var courseStructureState={
  width:90,
  type: 2, //状态  正常或者禁用按钮
  property:"state",
  label:"状态"
}
export var coursestructureOperation={
  width:300,
  type: 3, //操作
  label:"操作",
  buttonList:[{
    buttonType:"primary",
    label:"层级管理",
    clickFn(row){
          router.push({
            path:"/course/edit/structure",
            query:{
              definitionId:row.id,
              definitionName:row.name
            }
          })
        }
    },{
      buttonType:"primary",
      label:"编辑",
      callBackFn:"edit",
      clickFn(row){
        vm.edit(row);
        // const commonTableDrawer = ref(null);
        // console.log("commonTableDrawer",vm.edit)
        // console.log(vm.userProfile) // 打印组件中的data
        // vm.clearForm() // 调用组件中的methods
      }
    },{
      buttonType:"primary",
      label:"禁用",
      labelData:{
        labelType:1,//1禁用启用模式
      },
      disabled:true,//不可点
      clickFn(row){
        vm.changeState(row);
      }
    },{
      buttonType:"danger",
      label:"删除",
      dialog: {
        type:1,
        data:{
          title:"删除课程结构定义",
          content:"确定删除课程结构定义吗？"
        }
      },//弹框样式
      clickFn(row){
        vm.deleteFn(row);
      }
    }
  ]
}
//课程结构（层级状态）
export var structureState={
  width:90,
  type: 2, //状态  正常或者禁用按钮
  property:"state",
  label:"状态",
}
//课程结构（层级）操作
//新增加了关联层级、复制层级，并将原来的复制层级修改成同步层级
export var structureOperation={
  width:300,
  type: 3, //操作
  label:"操作",
  buttonList:[/*{
    buttonType:"primary",
    label:"复制",
    isHidden:false, //是否隐藏
    callBackFn:"fzFn",
    clickFn(row){
      vm.fzFn(row);
    }
  },*/{
    buttonType:"primary", //之前的复制层级，为了新添加的层级功能改成同步层级
    label:"设置标签",
    isHidden: true,
    callBackFn:"tag",
    clickFn(row){
      vm.tag(row);
    }
  },{
    buttonType:"primary", //之前的复制层级，为了新添加的层级功能改成同步层级
    label:"同步",
    isHidden:false, //是否隐藏
    callBackFn:"copy",
    clickFn(row){
      vm.copy(row);
    }
  },{
    buttonType:"primary",
    label:"关联",
    isHidden:false, //是否隐藏
    callBackFn:"relate",
    clickFn(row){
      vm.relate(row);
    }
  },{
    buttonType:"primary",
    label:"编辑",
    callBackFn:"edit",
    clickFn(row){
      vm.edit(row);
    }
  },{
    buttonType:"primary",
    label:"禁用", //启用
    labelData:{
      labelType:1,//是否禁用启用模式
    },
    clickFn(row){
      vm.changeState(row);
    }
  },{
    buttonType:"danger",
    label:"删除",
    dialog: {
      data:{
        type:1,
        title:"删除层级",
        content:"确定删除课程结构定义吗？"
      }
    },//弹框样式
    clickFn(row){
      vm.deleteFn(row);
    }
  }
  ]
}
//课程机构层级
export var struceureLogo={
  type:7,
  label:"层级图标",
  prop:"logo",
  activeValue:1,
  inactiveValue:0,
  changeFile:(file)=>{
    //图片改变
    vm.changeFile(file);
  }
}

