/**
 * Created with JetBrains PhpStorm.
 * User: taoqili
 * Date: 12-6-12
 * Time: 下午5:02
 * To change this template use File | Settings | File Templates.
 */
UE.I18N['zh-cn'] = {
  labelMap: {
    anchor: '锚点',
    undo: '撤销',
    redo: '重做',
    bold: '加粗',
    indent: '首行缩进',
    snapscreen: '截图',
    italic: '斜体',
    underline: '下划线',
    strikethrough: '删除线',
    subscript: '下标',
    fontborder: '字符边框',
    superscript: '上标',
    formatmatch: '格式刷',
    source: '源代码',
    blockquote: '引用',
    pasteplain: '纯文本粘贴模式',
    selectall: '全选',
    print: '打印',
    preview: '预览',
    horizontal: '分隔线',
    removeformat: '清除格式',
    time: '时间',
    date: '日期',
    unlink: '取消链接',
    insertrow: '前插入行',
    insertcol: '前插入列',
    mergeright: '右合并单元格',
    mergedown: '下合并单元格',
    deleterow: '删除行',
    deletecol: '删除列',
    splittorows: '拆分成行',
    splittocols: '拆分成列',
    splittocells: '完全拆分单元格',
    deletecaption: '删除表格标题',
    inserttitle: '插入标题',
    mergecells: '合并多个单元格',
    deletetable: '删除表格',
    cleardoc: '清空文档',
    insertparagraphbeforetable: '表格前插入行',
    insertcode: '代码语言',
    fontfamily: '字体',
    fontsize: '字号',
    paragraph: '段落格式',
    simpleupload: '单图上传',
    insertimage: '多图上传',
    edittable: '表格属性',
    edittd: '单元格属性',
    link: '超链接',
    emotion: '表情',
    spechars: '特殊字符',
    searchreplace: '查询替换',
    map: 'Baidu地图',
    gmap: 'Google地图',
    insertvideo: '视频',
    help: '帮助',
    justifyleft: '居左对齐',
    justifyright: '居右对齐',
    justifycenter: '居中对齐',
    justifyjustify: '两端对齐',
    forecolor: '字体颜色',
    backcolor: '背景色',
    insertorderedlist: '有序列表',
    insertunorderedlist: '无序列表',
    fullscreen: '全屏',
    directionalityltr: '从左向右输入',
    directionalityrtl: '从右向左输入',
    rowspacingtop: '段前距',
    rowspacingbottom: '段后距',
    pagebreak: '分页',
    insertframe: '插入Iframe',
    imagenone: '默认',
    imageleft: '左浮动',
    imageright: '右浮动',
    attachment: '附件',
    imagecenter: '居中',
    wordimage: '图片转存',
    lineheight: '行间距',
    edittip: '编辑提示',
    customstyle: '自定义标题',
    autotypeset: '自动排版',
    webapp: '百度应用',
    touppercase: '字母大写',
    tolowercase: '字母小写',
    background: '背景',
    template: '模板',
    scrawl: '涂鸦',
    music: '音乐',
    inserttable: '插入表格',
    drafts: '从草稿箱加载',
    charts: '图表'
  },
  insertorderedlist: {
    num: '1,2,3...',
    num1: '1),2),3)...',
    num2: '(1),(2),(3)...',
    cn: '一,二,三....',
    cn1: '一),二),三)....',
    cn2: '(一),(二),(三)....',
    decimal: '1,2,3...',
    'lower-alpha': 'a,b,c...',
    'lower-roman': 'i,ii,iii...',
    'upper-alpha': 'A,B,C...',
    'upper-roman': 'I,II,III...'
  },
  insertunorderedlist: {
    circle: '○ 大圆圈',
    disc: '● 小黑点',
    square: '■ 小方块 ',
    dash: '— 破折号',
    dot: ' 。 小圆圈'
  },
  paragraph: { p: '段落', h1: '标题 1', h2: '标题 2', h3: '标题 3', h4: '标题 4', h5: '标题 5', h6: '标题 6' },
  fontfamily: {
    songti: '宋体',
    kaiti: '楷体',
    heiti: '黑体',
    lishu: '隶书',
    yahei: '微软雅黑',
    andaleMono: 'andale mono',
    arial: 'arial',
    arialBlack: 'arial black',
    comicSansMs: 'comic sans ms',
    impact: 'impact',
    timesNewRoman: 'times new roman'
  },
  customstyle: {
    yjbt: '一级目录',
    ejbt: '二级目录',
    sjbt: '三级目录',
    zw: '正文'
  },
  autoupload: {
    exceedSizeError: '文件大小超出限制',
    exceedTypeError: '文件格式不允许',
    jsonEncodeError: '服务器返回格式错误',
    loading: '正在上传...',
    loadError: '上传错误',
    errorLoadConfig: '后端配置项没有正常加载，上传插件不能正常使用！'
  },
  simpleupload: {
    exceedSizeError: '文件大小超出限制',
    exceedTypeError: '文件格式不允许',
    jsonEncodeError: '服务器返回格式错误',
    loading: '正在上传...',
    loadError: '上传错误',
    errorLoadConfig: '后端配置项没有正常加载，上传插件不能正常使用！'
  },
  elementPathTip: '元素路径',
  wordCountTip: '字数统计',
  wordCountMsg: '当前已输入{#count}个字符, 您还可以输入{#leave}个字符。 ',
  wordOverFlowMsg: '<span style="color:red;">字数超出最大允许值，服务器可能拒绝保存！</span>',
  ok: '确认',
  cancel: '取消',
  closeDialog: '关闭对话框',
  tableDrag: '表格拖动必须引入uiUtils.js文件！',
  autofloatMsg: '工具栏浮动依赖编辑器UI，您首先需要引入UI文件!',
  loadconfigError: '获取后台配置项请求出错，上传功能将不能正常使用！',
  loadconfigFormatError: '后台配置项返回格式出错，上传功能将不能正常使用！',
  loadconfigHttpError: '请求后台配置项http错误，上传功能将不能正常使用！',
  snapScreen_plugin: {
    browserMsg: '仅支持IE浏览器！',
    callBackErrorMsg: '服务器返回数据有误，请检查配置项之后重试。',
    uploadErrorMsg: '截图上传失败，请检查服务器端环境! '
  },
  insertcode: {
    as3: 'ActionScript 3',
    bash: 'Bash/Shell',
    cpp: 'C/C++',
    css: 'CSS',
    cf: 'ColdFusion',
    'c#': 'C#',
    delphi: 'Delphi',
    diff: 'Diff',
    erlang: 'Erlang',
    groovy: 'Groovy',
    html: 'HTML',
    java: 'Java',
    jfx: 'JavaFX',
    js: 'JavaScript',
    pl: 'Perl',
    php: 'PHP',
    plain: 'Plain Text',
    ps: 'PowerShell',
    python: 'Python',
    ruby: 'Ruby',
    scala: 'Scala',
    sql: 'SQL',
    vb: 'Visual Basic',
    xml: 'XML'
  },
  confirmClear: '确定清空当前文档么？',
  contextMenu: {
    delete: '删除',
    selectall: '全选',
    deletecode: '删除代码',
    cleardoc: '清空文档',
    confirmclear: '确定清空当前文档么？',
    unlink: '删除超链接',
    paragraph: '段落格式',
    edittable: '表格属性',
    aligntd: '单元格对齐方式',
    aligntable: '表格对齐方式',
    tableleft: '左浮动',
    tablecenter: '居中显示',
    tableright: '右浮动',
    edittd: '单元格属性',
    setbordervisible: '设置表格边线可见',
    justifyleft: '左对齐',
    justifyright: '右对齐',
    justifycenter: '居中对齐',
    justifyjustify: '两端对齐',
    table: '表格',
    inserttable: '插入表格',
    deletetable: '删除表格',
    insertparagraphbefore: '前插入段落',
    insertparagraphafter: '后插入段落',
    deleterow: '删除当前行',
    deletecol: '删除当前列',
    insertrow: '前插入行',
    insertcol: '左插入列',
    insertrownext: '后插入行',
    insertcolnext: '右插入列',
    insertcaption: '插入表格名称',
    deletecaption: '删除表格名称',
    inserttitle: '插入表格标题行',
    deletetitle: '删除表格标题行',
    inserttitlecol: '插入表格标题列',
    deletetitlecol: '删除表格标题列',
    averageDiseRow: '平均分布各行',
    averageDisCol: '平均分布各列',
    mergeright: '向右合并',
    mergeleft: '向左合并',
    mergedown: '向下合并',
    mergecells: '合并单元格',
    splittocells: '完全拆分单元格',
    splittocols: '拆分成列',
    splittorows: '拆分成行',
    tablesort: '表格排序',
    enablesort: '设置表格可排序',
    disablesort: '取消表格可排序',
    reversecurrent: '逆序当前',
    orderbyasc: '按ASCII字符升序',
    reversebyasc: '按ASCII字符降序',
    orderbynum: '按数值大小升序',
    reversebynum: '按数值大小降序',
    borderbk: '边框底纹',
    setcolor: '表格隔行变色',
    unsetcolor: '取消表格隔行变色',
    setbackground: '选区背景隔行',
    unsetbackground: '取消选区背景',
    redandblue: '红蓝相间',
    threecolorgradient: '三色渐变',
    copy: '复制(Ctrl + c)',
    copymsg: "浏览器不支持,请使用 'Ctrl + c'",
    paste: '粘贴(Ctrl + v)',
    pastemsg: "浏览器不支持,请使用 'Ctrl + v'"
  },
  copymsg: "浏览器不支持,请使用 'Ctrl + c'",
  pastemsg: "浏览器不支持,请使用 'Ctrl + v'",
  anthorMsg: '链接',
  clearColor: '清空颜色',
  standardColor: '标准颜色',
  themeColor: '主题颜色',
  property: '属性',
  default: '默认',
  modify: '修改',
  justifyleft: '左对齐',
  justifyright: '右对齐',
  justifycenter: '居中',
  justify: '默认',
  clear: '清除',
  anchorMsg: '锚点',
  delete: '删除',
  clickToUpload: '点击上传',
  unset: '尚未设置语言文件',
  t_row: '行',
  t_col: '列',
  more: '更多',
  pasteOpt: '粘贴选项',
  pasteSourceFormat: '保留源格式',
  tagFormat: '只保留标签',
  pasteTextFormat: '只保留文本',
  autoTypeSet: {
    mergeLine: '合并空行',
    delLine: '清除空行',
    removeFormat: '清除格式',
    indent: '首行缩进',
    alignment: '对齐方式',
    imageFloat: '图片浮动',
    removeFontsize: '清除字号',
    removeFontFamily: '清除字体',
    removeHtml: '清除冗余HTML代码',
    pasteFilter: '粘贴过滤',
    run: '执行',
    symbol: '符号转换',
    bdc2sb: '全角转半角',
    tobdc: '半角转全角'
  },

  background: {
    static: {
      lang_background_normal: '背景设置',
      lang_background_local: '在线图片',
      lang_background_set: '选项',
      lang_background_none: '无背景色',
      lang_background_colored: '有背景色',
      lang_background_color: '颜色设置',
      lang_background_netimg: '网络图片',
      lang_background_align: '对齐方式',
      lang_background_position: '精确定位',
      repeatType: { options: ['居中', '横向重复', '纵向重复', '平铺', '自定义'] }

    },
    noUploadImage: '当前未上传过任何图片！',
    toggleSelect: '单击可切换选中状态\n原图尺寸: '
  },
  //= ==============dialog i18N=======================
  insertimage: {
    static: {
      lang_tab_remote: '插入图片', // 节点
      lang_tab_upload: '本地上传',
      lang_tab_online: '在线管理',
      lang_tab_search: '图片搜索',
      lang_input_url: '地 址：',
      lang_input_size: '大 小：',
      lang_input_width: '宽度',
      lang_input_height: '高度',
      lang_input_border: '边 框：',
      lang_input_vhspace: '边 距：',
      lang_input_title: '描 述：',
      lang_input_align: '图片浮动方式：',
      lang_imgLoading: '　图片加载中……',
      lang_start_upload: '开始上传',
      lock: { title: '锁定宽高比例' }, // 属性
      searchType: { title: '图片类型', options: ['新闻', '壁纸', '表情', '头像'] }, // select的option
      searchTxt: { value: '请输入搜索关键词' },
      searchBtn: { value: '百度一下' },
      searchReset: { value: '清空搜索' },
      noneAlign: { title: '无浮动' },
      leftAlign: { title: '左浮动' },
      rightAlign: { title: '右浮动' },
      centerAlign: { title: '居中独占一行' }
    },
    uploadSelectFile: '点击选择图片',
    uploadAddFile: '继续添加',
    uploadStart: '开始上传',
    uploadPause: '暂停上传',
    uploadContinue: '继续上传',
    uploadRetry: '重试上传',
    uploadDelete: '删除',
    uploadTurnLeft: '向左旋转',
    uploadTurnRight: '向右旋转',
    uploadPreview: '预览中',
    uploadNoPreview: '不能预览',
    updateStatusReady: '选中_张图片，共_KB。',
    updateStatusConfirm: '已成功上传_张照片，_张照片上传失败',
    updateStatusFinish: '共_张（_KB），_张上传成功',
    updateStatusError: '，_张上传失败。',
    errorNotSupport: 'WebUploader 不支持您的浏览器！如果你使用的是IE浏览器，请尝试升级 flash 播放器。',
    errorLoadConfig: '后端配置项没有正常加载，上传插件不能正常使用！',
    errorExceedSize: '文件大小超出',
    errorFileType: '文件格式不允许',
    errorInterrupt: '文件传输中断',
    errorUploadRetry: '上传失败，请重试',
    errorHttp: 'http请求错误',
    errorServerUpload: '服务器返回出错',
    remoteLockError: '宽高不正确,不能所定比例',
    numError: '请输入正确的长度或者宽度值！例如：123，400',
    imageUrlError: '不允许的图片格式或者图片域！',
    imageLoadError: '图片加载失败！请检查链接地址或网络状态！',
    searchRemind: '请输入搜索关键词',
    searchLoading: '图片加载中，请稍后……',
    searchRetry: ' :( ，抱歉，没有找到图片！请重试一次！'
  },
  attachment: {
    static: {
      lang_tab_upload: '上传附件',
      lang_tab_online: '在线附件',
      lang_start_upload: '开始上传',
      lang_drop_remind: '可以将文件拖到这里，单次最多可选100个文件'
    },
    uploadSelectFile: '点击选择文件',
    uploadAddFile: '继续添加',
    uploadStart: '开始上传',
    uploadPause: '暂停上传',
    uploadContinue: '继续上传',
    uploadRetry: '重试上传',
    uploadDelete: '删除',
    uploadTurnLeft: '向左旋转',
    uploadTurnRight: '向右旋转',
    uploadPreview: '预览中',
    updateStatusReady: '选中_个文件，共_KB。',
    updateStatusConfirm: '已成功上传_个文件，_个文件上传失败',
    updateStatusFinish: '共_个（_KB），_个上传成功',
    updateStatusError: '，_张上传失败。',
    errorNotSupport: 'WebUploader 不支持您的浏览器！如果你使用的是IE浏览器，请尝试升级 flash 播放器。',
    errorLoadConfig: '后端配置项没有正常加载，上传插件不能正常使用！',
    errorExceedSize: '文件大小超出',
    errorFileType: '文件格式不允许',
    errorInterrupt: '文件传输中断',
    errorUploadRetry: '上传失败，请重试',
    errorHttp: 'http请求错误',
    errorServerUpload: '服务器返回出错'
  },
  insertvideo: {
    static: {
      lang_tab_insertV: '插入视频',
      lang_tab_searchV: '搜索视频',
      lang_tab_uploadV: '上传视频',
      lang_video_url: '视频网址',
      lang_video_size: '视频尺寸',
      lang_videoW: '宽度',
      lang_videoH: '高度',
      lang_alignment: '对齐方式',
      videoSearchTxt: { value: '请输入搜索关键字！' },
      videoType: { options: ['全部', '热门', '娱乐', '搞笑', '体育', '科技', '综艺'] },
      videoSearchBtn: { value: '百度一下' },
      videoSearchReset: { value: '清空结果' },

      lang_input_fileStatus: ' 当前未上传文件',
      startUpload: { style: 'background:url(upload.png) no-repeat;' },

      lang_upload_size: '视频尺寸',
      lang_upload_width: '宽度',
      lang_upload_height: '高度',
      lang_upload_alignment: '对齐方式',
      lang_format_advice: '建议使用mp4格式.'

    },
    numError: '请输入正确的数值，如123,400',
    floatLeft: '左浮动',
    floatRight: '右浮动',
    '"default"': '默认',
    block: '独占一行',
    urlError: '输入的视频地址有误，请检查后再试！',
    loading: ' &nbsp;视频加载中，请等待……',
    clickToSelect: '点击选中',
    goToSource: '访问源视频',
    noVideo: ' &nbsp; &nbsp;抱歉，找不到对应的视频，请重试！',

    browseFiles: '浏览文件',
    uploadSuccess: '上传成功!',
    delSuccessFile: '从成功队列中移除',
    delFailSaveFile: '移除保存失败文件',
    statusPrompt: ' 个文件已上传！ ',
    flashVersionError: '当前Flash版本过低，请更新FlashPlayer后重试！',
    flashLoadingError: 'Flash加载失败!请检查路径或网络状态',
    fileUploadReady: '等待上传……',
    delUploadQueue: '从上传队列中移除',
    limitPrompt1: '单次不能选择超过',
    limitPrompt2: '个文件！请重新选择！',
    delFailFile: '移除失败文件',
    fileSizeLimit: '文件大小超出限制！',
    emptyFile: '空文件无法上传！',
    fileTypeError: '文件类型不允许！',
    unknownError: '未知错误！',
    fileUploading: '上传中，请等待……',
    cancelUpload: '取消上传',
    netError: '网络错误',
    failUpload: '上传失败!',
    serverIOError: '服务器IO错误！',
    noAuthority: '无权限！',
    fileNumLimit: '上传个数限制',
    failCheck: '验证失败，本次上传被跳过！',
    fileCanceling: '取消中，请等待……',
    stopUploading: '上传已停止……',

    uploadSelectFile: '点击选择文件',
    uploadAddFile: '继续添加',
    uploadStart: '开始上传',
    uploadPause: '暂停上传',
    uploadContinue: '继续上传',
    uploadRetry: '重试上传',
    uploadDelete: '删除',
    uploadTurnLeft: '向左旋转',
    uploadTurnRight: '向右旋转',
    uploadPreview: '预览中',
    updateStatusReady: '选中_个文件，共_KB。',
    updateStatusConfirm: '成功上传_个，_个失败',
    updateStatusFinish: '共_个(_KB)，_个成功上传',
    updateStatusError: '，_张上传失败。',
    errorNotSupport: 'WebUploader 不支持您的浏览器！如果你使用的是IE浏览器，请尝试升级 flash 播放器。',
    errorLoadConfig: '后端配置项没有正常加载，上传插件不能正常使用！',
    errorExceedSize: '文件大小超出',
    errorFileType: '文件格式不允许',
    errorInterrupt: '文件传输中断',
    errorUploadRetry: '上传失败，请重试',
    errorHttp: 'http请求错误',
    errorServerUpload: '服务器返回出错'
  },
  webapp: {
    tip1: '本功能由百度APP提供，如看到此页面，请各位站长首先申请百度APPKey!',
    tip2: '申请完成之后请至ueditor.config.js中配置获得的appkey! ',
    applyFor: '点此申请',
    anthorApi: '百度API'
  },
  template: {
    static: {
      lang_template_bkcolor: '背景颜色',
      lang_template_clear: '保留原有内容',
      lang_template_select: '选择模板'
    },
    blank: '空白文档',
    blog: '博客文章',
    resume: '个人简历',
    richText: '图文混排',
    sciPapers: '科技论文'

  },
  scrawl: {
    static: {
      lang_input_previousStep: '上一步',
      lang_input_nextsStep: '下一步',
      lang_input_clear: '清空',
      lang_input_addPic: '添加背景',
      lang_input_ScalePic: '缩放背景',
      lang_input_removePic: '删除背景',
      J_imgTxt: { title: '添加背景图片' }
    },
    noScarwl: '尚未作画，白纸一张~',
    scrawlUpLoading: '涂鸦上传中,别急哦~',
    continueBtn: '继续',
    imageError: '糟糕，图片读取失败了！',
    backgroundUploading: '背景图片上传中,别急哦~'
  },
  music: {
    static: {
      lang_input_tips: '输入歌手/歌曲/专辑，搜索您感兴趣的音乐！',
      J_searchBtn: { value: '搜索歌曲' }
    },
    emptyTxt: '未搜索到相关音乐结果，请换一个关键词试试。',
    chapter: '歌曲',
    singer: '歌手',
    special: '专辑',
    listenTest: '试听'
  },
  anchor: {
    static: {
      lang_input_anchorName: '锚点名字：'
    }
  },
  charts: {
    static: {
      lang_data_source: '数据源：',
      lang_chart_format: '图表格式：',
      lang_data_align: '数据对齐方式',
      lang_chart_align_same: '数据源与图表X轴Y轴一致',
      lang_chart_align_reverse: '数据源与图表X轴Y轴相反',
      lang_chart_title: '图表标题',
      lang_chart_main_title: '主标题：',
      lang_chart_sub_title: '子标题：',
      lang_chart_x_title: 'X轴标题：',
      lang_chart_y_title: 'Y轴标题：',
      lang_chart_tip: '提示文字',
      lang_cahrt_tip_prefix: '提示文字前缀：',
      lang_cahrt_tip_description: '仅饼图有效， 当鼠标移动到饼图中相应的块上时，提示框内的文字的前缀',
      lang_chart_data_unit: '数据单位',
      lang_chart_data_unit_title: '单位：',
      lang_chart_data_unit_description: '显示在每个数据点上的数据的单位， 比如： 温度的单位 ℃',
      lang_chart_type: '图表类型：',
      lang_prev_btn: '上一个',
      lang_next_btn: '下一个'
    }
  },
  emotion: {
    static: {
      lang_input_choice: '精选',
      lang_input_Tuzki: '兔斯基',
      lang_input_BOBO: 'BOBO',
      lang_input_lvdouwa: '绿豆蛙',
      lang_input_babyCat: 'baby猫',
      lang_input_bubble: '泡泡',
      lang_input_youa: '有啊'
    }
  },
  gmap: {
    static: {
      lang_input_address: '地址',
      lang_input_search: '搜索',
      address: { value: '北京' }
    },
    searchError: '无法定位到该地址!'
  },
  help: {
    static: {
      lang_input_about: '关于UEditor',
      lang_input_shortcuts: '快捷键',
      lang_input_introduction: 'UEditor是由百度web前端研发部开发的所见即所得富文本web编辑器，具有轻量，可定制，注重用户体验等特点。开源基于BSD协议，允许自由使用和修改代码。',
      lang_Txt_shortcuts: '快捷键',
      lang_Txt_func: '功能',
      lang_Txt_bold: '给选中字设置为加粗',
      lang_Txt_copy: '复制选中内容',
      lang_Txt_cut: '剪切选中内容',
      lang_Txt_Paste: '粘贴',
      lang_Txt_undo: '重新执行上次操作',
      lang_Txt_redo: '撤销上一次操作',
      lang_Txt_italic: '给选中字设置为斜体',
      lang_Txt_underline: '给选中字加下划线',
      lang_Txt_selectAll: '全部选中',
      lang_Txt_visualEnter: '软回车',
      lang_Txt_fullscreen: '全屏'
    }
  },
  insertframe: {
    static: {
      lang_input_address: '地址：',
      lang_input_width: '宽度：',
      lang_input_height: '高度：',
      lang_input_isScroll: '允许滚动条：',
      lang_input_frameborder: '显示框架边框：',
      lang_input_alignMode: '对齐方式：',
      align: { title: '对齐方式', options: ['默认', '左对齐', '右对齐', '居中'] }
    },
    enterAddress: '请输入地址!'
  },
  link: {
    static: {
      lang_input_text: '文本内容：',
      lang_input_url: '链接地址：',
      lang_input_title: '标题：',
      lang_input_target: '是否在新窗口打开：'
    },
    validLink: '只支持选中一个链接时生效',
    httpPrompt: '您输入的超链接中不包含http等协议名称，默认将为您添加http://前缀'
  },
  map: {
    static: {
      lang_city: '城市',
      lang_address: '地址',
      city: { value: '北京' },
      lang_search: '搜索',
      lang_dynamicmap: '插入动态地图'
    },
    cityMsg: '请选择城市',
    errorMsg: '抱歉，找不到该位置！'
  },
  searchreplace: {
    static: {
      lang_tab_search: '查找',
      lang_tab_replace: '替换',
      lang_search1: '查找',
      lang_search2: '查找',
      lang_replace: '替换',
      lang_searchReg: '支持正则表达式，添加前后斜杠标示为正则表达式，例如“/表达式/”',
      lang_searchReg1: '支持正则表达式，添加前后斜杠标示为正则表达式，例如“/表达式/”',
      lang_case_sensitive1: '区分大小写',
      lang_case_sensitive2: '区分大小写',
      nextFindBtn: { value: '下一个' },
      preFindBtn: { value: '上一个' },
      nextReplaceBtn: { value: '下一个' },
      preReplaceBtn: { value: '上一个' },
      repalceBtn: { value: '替换' },
      repalceAllBtn: { value: '全部替换' }
    },
    getEnd: '已经搜索到文章末尾！',
    getStart: '已经搜索到文章头部',
    countMsg: '总共替换了{#count}处！'
  },
  snapscreen: {
    static: {
      lang_showMsg: '截图功能需要首先安装UEditor截图插件！ ',
      lang_download: '点此下载',
      lang_step1: '第一步，下载UEditor截图插件并运行安装。',
      lang_step2: '第二步，插件安装完成后即可使用，如不生效，请重启浏览器后再试！'
    }
  },
  spechars: {
    static: {},
    tsfh: '特殊字符',
    lmsz: '罗马字符',
    szfh: '数学字符',
    rwfh: '日文字符',
    xlzm: '希腊字母',
    ewzm: '俄文字符',
    pyzm: '拼音字母',
    yyyb: '英语音标',
    zyzf: '其他'
  },
  edittable: {
    static: {
      lang_tableStyle: '表格样式',
      lang_insertCaption: '添加表格名称行',
      lang_insertTitle: '添加表格标题行',
      lang_insertTitleCol: '添加表格标题列',
      lang_orderbycontent: '使表格内容可排序',
      lang_tableSize: '自动调整表格尺寸',
      lang_autoSizeContent: '按表格文字自适应',
      lang_autoSizePage: '按页面宽度自适应',
      lang_example: '示例',
      lang_borderStyle: '表格边框',
      lang_color: '颜色:'
    },
    captionName: '表格名称',
    titleName: '标题',
    cellsName: '内容',
    errorMsg: '有合并单元格，不可排序'
  },
  edittip: {
    static: {
      lang_delRow: '删除整行',
      lang_delCol: '删除整列'
    }
  },
  edittd: {
    static: {
      lang_tdBkColor: '背景颜色:'
    }
  },
  formula: {
    static: {
    }
  },
  wordimage: {
    static: {
      lang_resave: '转存步骤',
      uploadBtn: { src: 'upload.png', alt: '上传' },
      clipboard: { style: 'background: url(copy.png) -153px -1px no-repeat;' },
      lang_step: '1、点击顶部复制按钮，将地址复制到剪贴板；2、点击添加照片按钮，在弹出的对话框中使用Ctrl+V粘贴地址；3、点击打开后选择图片上传流程。'
    },
    fileType: '图片',
    flashError: 'FLASH初始化失败，请检查FLASH插件是否正确安装！',
    netError: '网络连接错误，请重试！',
    copySuccess: '图片地址已经复制！',
    flashI18n: {} // 留空默认中文
  },
  autosave: {
    saving: '保存中...',
    success: '本地保存成功'
  }
}
