import router from '../router'
import * as courseData from './course/courseData.js'
import * as helpData from './help/helpData.js'
//课程结构表格
export var courseStructure={
  list:[
    {
      type:1,//1 普通文字列
      property:"name",
      label:"课程结构名称",
    },
    {
      type: 1,
      property:"course.courseName",
      label:"课程名称",
    },
    {
      type: 1,
      property:"standard.stdlevelName",
      label:"标准课程结构",
    },
    {
      type: 1,
      property:"haveResource",
      label:"是否带课程资源",
      formatter: function (row, column){
        var isHava="否";
        if(row == 0){
          isHava="否";
        }else{
          isHava="是";
        }
        return isHava;
      }
    },
    courseData.courseStructureState,
    courseData.coursestructureOperation
  ]
}

//结构（层级）表格
export var structureEdit={
  list:[
    {
      type:1,//1 普通文字列
      property:"name",
      label:"名称",
    },
    {
      type: 1,
      property:"parent.name",
      label:"隶属上级",
    },
    {
      type: 1,
      property:"course.courseName",
      label:"课程名称",
    },
    {
      type: 1,
      property:"acttype.acttypeName",
      label:"活动分类",
    },
    {
      type: 1,
      property:"rank",
      label:"排序",
    },
    courseData.structureState,
    courseData.structureOperation
  ]
}

//帮助中心的表格
export var helpList={
  list:[
    {
      type:1,//1 普通文字列
      property:"name",
      label:"帮助主题",
    },{
      type:1,//1 普通文字列
      property:"topictype.topictypeName",
      label:"分类名称",
    },helpData.helpTop,{
      width: 180,
      type:1,//1 普通文字列
      property:"createdAt",
      label:"创建时间",
    },
    helpData.helpState,
    helpData.helpOperation
  ]
}
