import {struceureLogo} from "./course/courseData"
export var courseStructureDrawer={
  list:[{//0
    type:2, //1普通输入框 2展示文字 3 textarea输入框 4选择下拉框 5数字输入框（排序）6开关状态是否类 7传图标
    label:"课程名称:",
    prop:"courseName",
    className:"mFlex",
  },{//2
    type:4,
    label:"标准课程结构类型:",
    prop:"stdlevelId",
    placeholder:"请选择标准课程结构类型",
    rules:[
      { required: true, message: '请选择标准课程结构类型' },
    ],
    drop:{
      des:{ //循环列表里面对应的字段
        id:"id",
        name:"name",
        value:"value" //多出来的，如果有value这个字段一定要有对应的model
      },
      model:{//应该赋值的返回的字段
        id:"stdlevelId",
        value:"stdlevelValue"
      },
      list:[]
    }
  },{//1
    type:1,
    label:"课程结构名称:",
    prop:"name",
    placeholder:"请输入课程结构名称",
    rules:[
      { required: true, message: '请输入课程结构名称' },
    ]
  },{//3
    type:4,
    label:"隶属上级:",
    prop:"pid",
    placeholder:"请选择隶属上级",
    rules:[
      { required: true, message: '请选择隶属上级'},
    ],
    drop: {
      des:{ //循环列表里面对应的字段
        id:"id",
        name:"name"
      },
      list:[]
    }
  },{
    type:1,
    label:"课程结构描述:",
    prop:"description",
    inputType:"textarea",
    placeholder:"请输入课程结构描述"
  },{
    type:5,
    label:"排列顺序",
    className:"mFlex",
    prop:"rank",
    rules:[
      { required: true, message: '请输入排列顺序'},
    ]
  },{
    type:6,
    label:"结构状态",
    prop:"state",
    rules:[
      { required: true, message: '请选择课程状态'},
    ],
    className:"mFlex",
    disabled:true,
    activeValue:1,
    inactiveValue:-1
  }]
};
export var structureDrawer={
  list:[{//0
    type:2, //1普通输入框 2展示文字 3 textarea输入框 4选择下拉框 5数字输入框（排序）6开关状态是否类
    label:"课程名称:",
    prop:"courseName",
    className:"mFlex",
  },{//1
    type:2, //1普通输入框 2展示文字 3 textarea输入框 4选择下拉框 5数字输入框（排序）6开关状态是否类
    label:"层级类别:",
    prop:"definitionName",
    className:"mFlex",
  },{//2
    type:1,
    label:"层级名称:",
    prop:"name",
    placeholder:"请输入层级名称",
    rules:[
      { required: true, message: '请输入层级名称' },
    ]
  },{//3
    type:4,
    label:"活动分类:",
    prop:"acttypeId",
    placeholder:"请选择活动分类",
    drop: {
      des:{ //循环列表里面对应的字段
        id:"id",
        name:"name"
      },
      list:[]
    }
  },{//4
    type:2, //1普通输入框 2展示文字 3 textarea输入框 4选择下拉框 5数字输入框（排序）6开关状态是否类
    label:"当前隶属于:",
    prop:"filterText",
    className:"mFlex",
  },{//5
    type:4,
    label:"隶属上级:",
    prop:"pid",
    placeholder:"请选择隶属上级",
    rules:[
      { required: true, message: '请选择隶属上级'},
    ],
    drop: {
      des:{ //循环列表里面对应的字段
        id:"id",
        name:"name"
      },
      list:[]
    }
  },{//6
    type:1,
    label:"层级序号:",
    prop:"value",
    placeholder:"请输入层级序号"
  },
    //   {
    //   type:1,
    //   label:"层级描述",
    //   prop:"description",
    //   inputType:"textarea",
    //   placeholder:"请输入层级描述"
    // },
    {//7
      type:8,
      label:"层级描述",
      prop:"description",
      inputType:"input",
      placeholder:"请输入层级描述"
    }
    ,{//8
      type:5,
      label:"排列顺序",
      className:"mFlex",
      prop:"rank",
      rules:[
        { required: true, message: '请输入排列顺序'},
      ]
    },{
      type:6,
      label:"是否是上课资源层级",
      prop:"isClass",
      className:"mFlex",
      rules:[
        { required: true, message: '请选择是否是上课资源层级'},
      ],
      activeValue:1,
      inactiveValue:0
    },{
      type:6,
      label:"层级状态",
      prop:"state",
      rules:[
        { required: true, message: '请选择层级状态'},
      ],
      className:"mFlex",
      activeValue:1,
      inactiveValue:-1
    },{
      type:6,
      label:"是否推荐到首页",
      prop:"recommend",
      className:"mFlex",
      rules:[
        { required: true, message: '请选择是否推荐到首页'},
      ],
      activeValue:1,
      inactiveValue:0
    },{
      type:6,
      label:"是否有体验课",
      prop:"haveFree",
      className:"mFlex",
      rules:[
        { required: true, message: '请选择是否有体验课'},
      ],
      activeValue:1,
      inactiveValue:0
    },struceureLogo]
};
